import React from 'react';
import QRCode from 'qrcode.react';
import { useAuth } from '../../config/AuthContext';
import { FaDownload, FaShareAlt } from 'react-icons/fa';
import AdminLayout from '../../Layouts/AdminLayout';
import logo from "../../Asset/logo-growto-secondary.png";

const GenerateQRPage = () => {
//   const { currentUser } = useAuth(); 

//   if (!currentUser) {
//     return <div>Loading...</div>;
//   }

  const userId = "GTPV20240808541"; 
  const providerName = "GrowTo"; // Example provider name

  const shareQR = () => {
    const qrCodeElement = document.getElementById("qr-code");

    if (qrCodeElement) {
        const canvas = qrCodeElement.querySelector("canvas");

        if (canvas) {
            canvas.toBlob((blob) => {
                if (blob) {
                    const url = URL.createObjectURL(blob);
                    const shareData = {
                        title: 'QR Code',
                        text: 'Here is my QR code!',
                        url: url,
                    };

                    navigator.share(shareData)
                        .then(() => console.log('Shared successfully'))
                        .catch((error) => console.error('Error sharing', error));
                } else {
                    console.error("Blob creation failed");
                }
            });
        } else {
            console.error("No canvas found");
        }
    } else {
        console.error("QR code element not found");
    }
};

const downloadQR = () => {
    const qrCodeElement = document.getElementById("qr-code");
    const canvas = qrCodeElement.querySelector("canvas");

    if (canvas) {
        canvas.toBlob((blob) => {
            const url = URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = url;
            a.download = 'Growto-qr.png';
            a.click();
            URL.revokeObjectURL(url);
        });
    } else {
        console.error("No canvas found for download");
    }
};


  return (
    <AdminLayout>
 <div id="qr-code" className="flex flex-col items-center justify-center  bg-white p-6">
    <div className="w-full max-w-sm overflow-hidden">
        {/* Google Pay Header */}
        <div className="flex flex-col p-4 items-center">
            <img
                loading="eager"
                fetchpriority="auto"
                src={logo}
                className="w-[100px] h-6"
                alt="logo"
            />
            <span className="text-sm text-gray-600">For Business</span>
        </div>


        {/* QR Code Centered */}
        <div className="flex justify-center py-6">
            <QRCode  value={userId} size={300} />
        </div>

        {/* Download and Share Buttons */}
        <div className="mt-4 flex space-x-4 justify-center">
            <button onClick={downloadQR} className="bg-black text-white px-4 py-2 rounded">
                <FaDownload className="inline mr-2" /> Download
            </button>
            <button onClick={shareQR} className="bg-green-800 text-white px-4 py-2 rounded">
                <FaShareAlt className="inline mr-2" /> Share
            </button>
        </div>
    </div>
</div>

    </AdminLayout>
    
  );
};

export default GenerateQRPage;
