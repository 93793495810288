const NODE_ENV = "development";
console.log('NODE_ENV', NODE_ENV);
export const serverUrl = "https://fine-pink-tortoise-toga.cyclic.app/api/provider"
// export const serverUrl = "http://localhost:1000/api/provider"
// export const serverUrl = (NODE_ENV.toLowerCase() === 'development') ? 'https://serverpprod.hksoftware.in/api/v1/users' : "http://localhost:1000/api/user";

// Function to generate custom ID in the format GT-yyyyMMdd-xxx
export function generateCustomId() {
    const date = new Date();
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    const randomThreeDigit = String(Math.floor(100 + Math.random() * 900)); // Random 3-digit number
  
    return `GTMC${year}${month}${day}${randomThreeDigit}`;
  }
  