import React from "react";
import {
  FiHome,
  FiUsers,
  FiCompass,
  FiClock,
  FiUser,
  FiSettings,
  FiLogOut,
} from "react-icons/fi";
import { BsFillStarFill } from "react-icons/bs";
import { PiPottedPlantDuotone } from "react-icons/pi";
import { Link, useLocation } from "react-router-dom";
import logo from "../Asset/Logo.png";

function SideBar() {
  const { pathname } = useLocation();

  return (
    <>
      <div className=" hidden sm:block  duration-300  h-screen lg:block  w-[250px]   bg-[#D0D9CD] ">
        <div className="font-bold text-lg flex items-center gap-x-3  h-20 justify-center ">
          <Link to="/" className="  w-6 h-6 ">
            <img loading="eager" fetchpriority="auto" src={logo} alt="logo" />
          </Link>

          <div className="tracking-wide text-green-800">Grow To</div>
        </div>

        <div className="pl-3 pt-4 mt-6 flex flex-col gap-y-2 text-gray-500 text-sm">
          <div className="text-gray-800 font-medium uppercase">Menu</div>
          <Link
            className={`px-2 py-3 hover:bg-green-800 hover:text-white rounded-lg cursor-pointer flex flex-row gap-2 mr-4 text-gray-800  ${
              pathname === "/" ? "bg-green-900 text-white font-bold" : ""
            }`}
            to="/Home"
          >
            <FiHome className="h-5 w-5 hover:text-green-600" />
            <span>Home</span>
          </Link>
          <Link
            className={`px-2 py-3 hover:bg-green-800 hover:text-white  rounded-lg cursor-pointer flex flex-row gap-2 mr-4 text-gray-800 ${
              pathname === "/Orders"
                ? " bg-green-900 text-white font-bold"
                : ""
            }`}
            to="/Orders"
          >
            <FiUsers className="h-5 w-5  hover:text-green-600" />
            <span>Orders</span>
          </Link>
          <Link
            className={`px-2 py-3 hover:bg-green-800 hover:text-white rounded-lg cursor-pointer flex flex-row gap-2 mr-4 text-gray-800  ${
              pathname === "/Manage"
                ? "bg-green-900 text-white font-bold"
                : ""
            }`}
            to="/Manage"
          >
            <FiCompass className="h-5 w-5 hover:text-green-600" />
            <span>Manage</span>
          </Link>
          <Link
            className={`px-2 py-3 hover:bg-green-800 hover:text-white rounded-lg cursor-pointer flex flex-row gap-2 mr-4 text-gray-800  ${
              pathname === "/users"
                ? "bg-green-900 text-white font-bold"
                : ""
            }`}
            to="/users"
          >
            <FiClock className="h-5 w-5 group-hover:text-green-600" />
            <span>Transcations</span>
          </Link>
        
          <div className="text-gray-800 font-medium uppercase">Account</div>

          <Link
            className={`px-2 py-3 hover:bg-green-800 hover:text-white rounded-lg cursor-pointer flex flex-row gap-2 mr-4 text-gray-800  ${
              pathname === "/myProfile"
                ? "bg-green-900 text-white font-bold"
                : ""
            }`}
            to="/myProfile"
          >
            <FiUser className="h-5 w-5 group-hover:text-green-600" />
            <span>My Profile</span>
          </Link>
        
          <Link
            className={`px-2 py-3 hover:bg-green-800 hover:text-white rounded-lg cursor-pointer flex flex-row gap-2 mr-4 text-gray-800  ${
              pathname === "/account"
                ? "bg-green-900 text-white font-bold"
                : ""
            }`}
            to="/account"
          >
            <FiSettings className="h-5 w-5 hover:text-green-600" />
            <span>Settings</span>
          </Link>
          <Link
            className={` absolute bottom-4  px-16 py-3 bg-red-800 rounded-lg cursor-pointer flex flex-row gap-2 mr-4 items-center justify-center text-center text-white font-bold `}
            to="/Home"
          >
            <FiLogOut className="h-5 w-5 hover:text-green-600" />
            <span>Logout</span>
          </Link>
        </div>
      </div>
    </>
  );
}

export default SideBar;
