import React from "react";
import SideBar from "./SideBar";
import AdminHeader from "./AdminHeader";
import AdminBottomNavigator from "./AdminBottomNav";
import  { Toaster } from 'react-hot-toast';
export default function AdminLayout({ children }) {
  return (
    <>
     <div className="flex flex-col sm:flex-row h-screen bg-[#f3f5f7]">
  {/* Sidebar */}
  <div className="flex-none">
    <SideBar />
  </div>

  {/* Main Content */}
  <div className="flex flex-col w-full overflow-hidden">
    {/* Admin Header */}
    <div className="flex-none">
      <AdminHeader />
    </div>
    <div><Toaster/></div>
    <div className="flex-1 overflow-y-scroll  p-1  mb-20   ">{children}</div>
  </div>
</div>
    <AdminBottomNavigator />

     
    </>
  );
}
