import { useState } from "react";
import { MdKeyboardArrowDown } from "react-icons/md";

export const ToggleSection = ({ title, content }) => {
    const [isOpen, setIsOpen] = useState(false);
  
    const toggleOpen = () => setIsOpen(!isOpen);
  
    return (
      <div className="my-3 rounded-lg overflow-hidden">
        <div
          className="p-4 bg-gray-200 hover:bg-[#1d433c] text-black hover:text-white rounded cursor-pointer flex justify-between items-center transition-all duration-700 ease-in-out"
          onClick={toggleOpen}
        >
          <h3 className="text-lg font-medium">{title}</h3>
          <span className={`transition-transform duration-700 ${isOpen ? "rotate-180" : "rotate-0"}`}>
            <MdKeyboardArrowDown className="text-xl font-bold" />
          </span>
        </div>
        {isOpen && (
          <div
            className={`p-4 bg-gray-100 text-gray-700 transition-max-height duration-700 ease-in-out ${
              isOpen ? "max-h-96" : "max-h-0"
            }`}
            style={{ overflow: "hidden" }}
          >
            {content}
          </div>
        )}
      </div>
    );
  };