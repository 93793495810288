import { Tab } from "@headlessui/react";
import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import BottomSheet from "../Helpers/BottomSheet";
import logo from "../../Asset/logo-growto-secondary.png";
import { doc, setDoc, getDoc } from "firebase/firestore";
import {
  RecaptchaVerifier,
  signInWithPhoneNumber,
  GoogleAuthProvider,
  signInWithPopup,
} from "firebase/auth";
import { auth, db } from "../../config/FirebaseConfig";

function AdminAuth() {
  const navigate = useNavigate();
  const [mobileNumber, setMobileNumber] = useState("");
  const [verificationCode, setVerificationCode] = useState("");
  const [registrationSuccess, setRegistrationSuccess] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [isLoginSheetOpen, setLoginSheetOpen] = useState(false);
  const [verificationId, setVerificationId] = useState("");

  const openLoginSheet = () => {
    setLoginSheetOpen(true);
  };

  const closeLoginSheet = () => {
    setLoginSheetOpen(false);
  };

  const sendOTP = async () => {
    const mobileRegex = /^[0-9]{10}$/;
    if (!mobileRegex.test(mobileNumber)) {
      setErrorMessage("Please enter a valid mobile number.");
      return;
    }

    try {
      window.recaptchaVerifier = new RecaptchaVerifier(
        "recaptcha-container",
        {
          size: "invisible",
          callback: (response) => {
            console.log("reCAPTCHA verified");
          },
          "expired-callback": () => {
            console.log("reCAPTCHA expired");
          },
        },
        auth
      );

      const phoneNumber = `+91${mobileNumber}`;
      const appVerifier = {
        ...window.recaptchaVerifier,
        appVerificationDisabledForTesting: true,
      };
      const confirmationResult = await signInWithPhoneNumber(
        auth,
        phoneNumber,
        appVerifier
      );

      setVerificationId(confirmationResult.verificationId);
      openLoginSheet(); // Open the login sheet to enter the OTP
      setErrorMessage("");
    } catch (error) {
      setErrorMessage(`Error sending OTP: ${error.message}`);
      console.error(error);
    }
  };

  const verifyOTP = async () => {
    try {
      const credential = window.firebase.auth.PhoneAuthProvider.credential(
        verificationId,
        verificationCode
      );
      await auth.signInWithCredential(credential);
      setRegistrationSuccess(true);
      setErrorMessage("");
      navigate("/home"); // Adjust as per your routing logic
    } catch (error) {
      setErrorMessage(`Error verifying OTP: ${error.message}`);
      console.error(error);
    }
  };

  const signInWithGoogle = async () => {
    const provider = new GoogleAuthProvider();
    try {
        // Sign in with Google
        const result = await signInWithPopup(auth, provider);

        // Get user details from the result
        const user = result.user;
        const userId = generateCustomUserId();

        // Create user data object
        const userData = {
            userId: userId,
            name: user.displayName,
            email: user.email,
            photoURL: user.photoURL,
            createdAt: new Date(),
        };

        // Reference to the user document in Firestore
        const userDocRef = doc(db, 'providers', userId);

        // Check if user already exists in the database
        const userDoc = await getDoc(userDocRef);

        if (!userDoc.exists()) {
            // Store user details in Firestore with the custom ID
            await setDoc(userDocRef, userData);
        }

        // Store custom user ID in localStorage
        localStorage.setItem('customUserId', userId);

        // Redirect to home page
        navigate('/home');
    } catch (error) {
        setErrorMessage(`Error signing in with Google: ${error.message}`);
        console.error(error);
    }
};


  // Generate a custom user ID
  const generateCustomUserId = () => {
    const currentDate = new Date();
    const year = currentDate.getFullYear();
    const month = String(currentDate.getMonth() + 1).padStart(2, '0'); // Months are zero-based
    const day = String(currentDate.getDate()).padStart(2, '0');

    const formattedDate = `${year}${month}${day}`; // Format as YYYYMMDD
    const randomNum = Math.floor(Math.random() * 1000); // Generate a random number between 0 and 999

    return `GTPV${formattedDate}${String(randomNum).padStart(3, '0')}`; // Custom ID format
  };

  

  return (
    <div className="flex min-h-full w-screen flex-col sm:supports-[min-height:100dvh]:min-h-[100dvh] md:grid md:grid-cols-2 lg:grid-cols-[60%_40%]">
      <div className="relative hidden flex-1 flex-col justify-center px-5 pt-8 text-[#FE7600] md:flex md:px-6 md:py-[22px] lg:px-8">
        <nav className="left-0 top-8 flex w-full px-6 sm:absolute md:top-[22px] md:px-6 lg:px-8">
          <h1 aria-label="">
            <div className="flex flex-col cursor-default items-start text-[20px] font-bold leading-none lg:text-[22px]">
              <img src={logo} alt="" className="w-26 h-8" />
              <span className="text-xs text-gray-800 ml-3">for Business</span>
            </div>
          </h1>
        </nav>

        <div
          className="flex flex-col items-center justify-center"
          aria-hidden="true"
        >
          <img src={logo} alt="" className="w-[90%] h-auto" />
          <div className="mt-6 items-center justify-center flex flex-col">
            <h3 className="text-lg md:text-xl text-gray-900 font-bold">
              List It. Rent It. Earn More. Transform with Ease!
            </h3>
            <h1 className="text-gray-800 text-3xl md:text-4xl xl:text-6xl font-bold">
              Designed to empower!
            </h1>
          </div>
        </div>
      </div>
      <div className="h-screen relative flex grow flex-col items-center justify-between bg-gray-200 px-5 py-8 text-black sm:rounded-t-[30px] md:rounded-none md:px-6">
        <nav className="flex w-full justify-start px-6 pb-8 md:hidden md:px-6 lg:px-8">
          <h1 aria-label="ChatGPT by OpenAI">
            <div className="flex flex-col cursor-default items-start text-[20px] font-bold leading-none lg:text-[22px]">
              <img src={logo} alt="" className="w-26 h-8" />
              <span className="text-xs text-gray-800 ml-3">for Business</span>
            </div>
          </h1>
        </nav>
        <div className="relative flex w-full grow flex-col items-center justify-center">
          <h2 className="text-center text-[20px] leading-[1.2] md:text-[32px] md:leading-8">
            Get started
          </h2>
          <div className="mt-5 w-full max-w-[440px]">
            <div className="grid gap-x-3 gap-y-2 grid-cols-1 ">
              <div className="mb-4">
                <input
                  id="mobileNumber"
                  type="text"
                  placeholder="Mobile Number"
                  className="relative flex h-12 items-center justify-center rounded-md text-center w-full text-base font-medium bg-white border border-gray-400 text-black"
                  value={mobileNumber}
                  onChange={(e) => setMobileNumber(e.target.value)}
                />
              </div>
              <div
                onClick={sendOTP}
                className="cursor-pointer relative flex h-12 items-center justify-center rounded-md text-center text-base font-medium bg-green-800 text-[#fff] hover:bg-green-700"
              >
                <div className="relative -top-[1px]">Register</div>
              </div>
              {/* Google Sign-In Button */}
              <div
                onClick={signInWithGoogle}
                className="cursor-pointer relative flex h-12 items-center justify-center rounded-md text-center text-base font-medium bg-blue-600 text-[#fff] hover:bg-blue-500 mt-4"
              >
                <div className="relative -top-[1px]">Continue with Google</div>
              </div>
            </div>

            {registrationSuccess && (
              <p className="text-green-600 mt-2 text-center">
                Registration successful
              </p>
            )}
          </div>
          {errorMessage && (
            <div className="text-red-600 text-center flex flex-row gap-2 items-center justify-center">
              <p className="">{errorMessage}</p>
            </div>
          )}
        </div>
        <BottomSheet isOpen={isLoginSheetOpen} onClose={closeLoginSheet}>
          <div className="grid gap-x-3 gap-y-2 grid-cols-1">
            <span className="text-center text-lg font-bold">
              Enter Verification Code
            </span>
            <div className="mb-4">
              <input
                id="verificationCode"
                type="text"
                placeholder="Verification Code"
                className="relative flex h-12 items-center justify-center rounded-md text-center w-full text-base font-medium bg-gray-300 border border-gray-400 text-black"
                value={verificationCode}
                onChange={(e) => setVerificationCode(e.target.value)}
              />
            </div>
            <div
              onClick={verifyOTP}
              className="cursor-pointer relative flex h-12 items-center justify-center rounded-md text-center text-base font-medium bg-green-800 text-[#fff] hover:bg-green-700"
            >
              <div className="relative -top-[1px]">Login</div>
            </div>
          </div>
        </BottomSheet>
        <div className="mt-10 flex flex-col justify-center ">
          <div className="flex justify-center text-[#cdcdcd] md:mb-3">
            <span className="h-[22px] w-auto font-bold text-black">
              Grow To
            </span>
          </div>
          <div className="py-3 text-xs">
            <a
              href="https://openai.com/policies/terms-of-use"
              target="_blank"
              className="mx-3 text-gray-500"
              rel="noreferrer"
            >
              Terms of use
            </a>
            <span className="text-gray-600">|</span>
            <a
              href="https://openai.com/policies/privacy-policy"
              target="_blank"
              className="mx-3 text-gray-500"
              rel="noreferrer"
            >
              Privacy policy
            </a>
          </div>
        </div>
      </div>
      <div id="recaptcha-container" /> {/* Recaptcha container */}
    </div>
  );
}

export default AdminAuth;
