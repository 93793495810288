import React, { useState } from 'react';
import { getAuth } from 'firebase/auth';
import { doc, updateDoc } from 'firebase/firestore';
import { db } from '../../config/FirebaseConfig';
import { FaArrowLeft, FaInfo } from 'react-icons/fa';
import AdminLayout from '../../Layouts/AdminLayout';

const AddBankAccount = () => {
  const [accountNumber, setAccountNumber] = useState('');
  const [confirmAccountNumber, setConfirmAccountNumber] = useState('');
  const [ifscCode, setIfscCode] = useState('');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    setLoading(true);

    // Basic validation
    if (accountNumber !== confirmAccountNumber) {
      setError('Account numbers do not match');
      setLoading(false);
      return;
    }

    if (!accountNumber || !ifscCode) {
      setError('Please fill in all fields');
      setLoading(false);
      return;
    }

    try {
      const auth = getAuth();
      const user = auth.currentUser;

      if (!user) {
        throw new Error('No authenticated user found');
      }

      const userRef = doc(db, 'users', user.uid);
      await updateDoc(userRef, {
        bankAccountNumber: accountNumber,
        ifscCode: ifscCode,
      });

      // Success message or redirect
      alert('Bank account added successfully!');
      // You might want to redirect the user or clear the form here
    } catch (err) {
      setError('Failed to add bank account. Please try again.');
      console.error('Error adding bank account:', err);
    } finally {
      setLoading(false);
    }
  };

  return (
   <AdminLayout>
       <div className="max-w-md mx-auto bg-white rounded-lg shadow-md">
        <div className="p-4 flex items-center border-b">
          <h1 className="text-xl font-semibold">Add Bank Account</h1>
        </div>
        
        <form onSubmit={handleSubmit} className="p-4 space-y-4">
          <input
            type="text"
            placeholder="Bank Account Number"
            value={accountNumber}
            onChange={(e) => setAccountNumber(e.target.value)}
            className="w-full p-2 border rounded-md"
          />
          <input
            type="text"
            placeholder="Confirm Bank Account Number"
            value={confirmAccountNumber}
            onChange={(e) => setConfirmAccountNumber(e.target.value)}
            className="w-full p-2 border rounded-md"
          />
          <input
            type="text"
            placeholder="IFSC CODE"
            value={ifscCode}
            onChange={(e) => setIfscCode(e.target.value)}
            className="w-full p-2 border rounded-md"
          />
          
          {error && <p className="text-red-500 text-sm">{error}</p>}
          
          <p className="text-sm text-gray-600">
            Note: This bank will be updated as primary in your Trading and Demat account. 
            You will receive payouts in primary bank account
          </p>
          
          <div className="bg-yellow-50 p-3 rounded-md flex items-start">
            <FaInfo className="text-yellow-500 mr-2 flex-shrink-0" size={20} />
            <p className="text-sm text-yellow-700">
              ₹1 will be credited to your account to verify the bank details
            </p>
          </div>
          
          <button
            type="submit"
            disabled={loading}
            className={`w-full p-2 bg-blue-600 text-white rounded-md hover:bg-blue-700 ${
              loading ? 'opacity-50 cursor-not-allowed' : ''
            }`}
          >
            {loading ? 'PROCESSING...' : 'CONTINUE'}
          </button>
        </form>
      </div>
   </AdminLayout>
  );
};

export default AddBankAccount;