import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import AdminLayout from "../../Layouts/AdminLayout";
import Loader from "../../Layouts/Loader";
import { doc, getDoc } from "firebase/firestore";
import { db } from "../../config/FirebaseConfig";
import { MachineCard, ProfileCard } from "../Manage/MachineCard";
import {
  FaBoxOpen,
  FaCheck,
  FaFileInvoiceDollar,
  FaMapMarkerAlt,
  FaTimes,
} from "react-icons/fa";

const ViewOrder = () => {
  const { orderId } = useParams();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [orderDetails, setOrderDetails] = useState(null);
  const [providerData, setProviderData] = useState(null);
  const [machineData, setMachineData] = useState(null);

  useEffect(() => {
    const fetchOrderDetails = async () => {
      try {
        const orderDocRef = doc(db, "orders", orderId);
        const docSnapshot = await getDoc(orderDocRef);

        if (docSnapshot.exists()) {
          const orderData = { id: docSnapshot.id, ...docSnapshot.data() };
          setOrderDetails(orderData);

          // Fetch provider and machine data concurrently
          const [providerData, machineData] = await Promise.all([
            fetchProviderData(orderData.ownerId),
            fetchMachineData(orderData.machineId),
          ]);

          setProviderData(providerData);
          setMachineData(machineData);
        } else {
          setError("Order not found");
        }
      } catch (error) {
        console.error("Error fetching order details:", error);
        setError("Failed to fetch order details");
      } finally {
        setLoading(false);
      }
    };

    fetchOrderDetails();
  }, [orderId]);

  const fetchProviderData = async (ownerId) => {
    try {
      const providerDocRef = doc(db, "provider", ownerId);
      const docSnapshot = await getDoc(providerDocRef);

      return docSnapshot.exists()
        ? { id: docSnapshot.id, ...docSnapshot.data() }
        : null;
    } catch (error) {
      console.error("Error fetching provider data:", error);
      return null;
    }
  };
  const [arrivalTime, setArrivalTime] = useState("");
  const [startTime, setStartTime] = useState("");
  const [breakTime, setBreakTime] = useState(0);
  const [workCompleteTime, setWorkCompleteTime] = useState("");

  const calculateTotalHours = () => {
    const arrival = new Date(arrivalTime);
    const start = new Date(startTime);
    const complete = new Date(workCompleteTime);

    const totalTime = (complete - start) / (1000 * 60 * 60); // in hours
    const breakDuration = breakTime / 60; // Convert minutes to hours
    return (totalTime - breakDuration).toFixed(2);
  };
  const fetchMachineData = async (machineId) => {
    try {
      const machineDocRef = doc(db, "machines", machineId);
      const docSnapshot = await getDoc(machineDocRef);

      return docSnapshot.exists()
        ? { id: docSnapshot.id, ...docSnapshot.data() }
        : null;
    } catch (error) {
      console.error("Error fetching machine data:", error);
      return null;
    }
  };

  if (loading) return <Loader />;

  if (error) return <div className="text-red-500">{error}</div>;

 
  const {
    id,
    orderDate,
    machineName,
    industry,
    totalAmount,
    orderStatus,
    paymentMethod,
    userAddress,
    userName,
    userPhoneNumber,
  } = orderDetails;
  return (
    <AdminLayout>
      <div className="mb-6 px-4 md:px-6 2xl:px-20 container mx-auto">
        <div className="mt-2 flex flex-col xl:flex-row jusitfy-center items-stretch w-full xl:space-x-8 space-y-4 md:space-y-6 xl:space-y-0">
          <div className="flex flex-col justify-start items-start w-full space-y-4 md:space-y-6 xl:space-y-8">
            <div className="flex flex-col justify-start items-start rounded-lg w-full gap-3">
              <div className="mt-6 md:mt-0 bg-white border border-gray-300 p-4 rounded-lg space-y-4 w-full">
                <div className="flex flex-row justify-between">
                  <h3 className="text-md font-semibold leading-6 text-gray-600">
                    {id}
                  </h3>
                  <h3 className="text-md font-bold leading-6 text-gray-800">
                    {new Date(orderDate.seconds * 1000).toLocaleDateString()}
                  </h3>
                </div>
                <hr className="my-1" />
                <div className="flex flex-row justify-between">
                  <div className="space-y-2">
                    <div className="text-md font-medium">Machine Name:</div>
                    <span>{machineName}</span>
                  </div>
                  <div className="space-y-2">
                    <div className="text-md font-medium">Category:</div>
                    <span>{industry}</span>
                  </div>
                </div>
                <hr className="my-2" />
                <div className="flex justify-center items-center">
                  <span className="text-center text-2xl font-bold">
                    ₹{totalAmount}
                  </span>
                </div>
                <hr className="my-2" />
                <div className="grid grid-cols-2">
                  <div className="space-y-2">
                    <div className="text-md font-medium">Order Status:</div>
                    <span className="text-yellow-500">{orderStatus}</span>
                  </div>
                  <div className="space-y-2">
                    <div className="text-md font-medium">
                      Payment - {paymentMethod}
                    </div>
                    <span className="text-red-500">Pending</span>
                  </div>
                </div>
                <hr className="my-2" />
                <div className="space-y-2 flex items-center">
                  <FaMapMarkerAlt className="text-gray-600 mr-2" />
                  <div>
                    <div className="text-md font-medium">Delivery Address:</div>
                    <span>{userAddress}</span>
                  </div>
                </div>
              </div>
            </div>

            <div className="flex justify-center md:flex-row flex-col items-stretch w-full space-y-4 md:space-y-0 md:space-x-6 xl:space-x-8">
              <div className="flex flex-col px-4 py-6 md:p-6 xl:p-8 w-full border border-gray-300 bg-white rounded-lg space-y-6">
                <h3 className="text-xl font-semibold leading-5 text-gray-800">
                  Summary
                </h3>
                <div className="flex justify-center items-center w-full space-y-4 flex-col border-gray-200 border-b pb-4">
                  <div className="flex justify-between w-full">
                    <p className="text-base leading-4 text-gray-800">
                      Total Amount
                    </p>
                    <p className="text-base leading-4 text-gray-600">
                      ₹{totalAmount}
                    </p>
                  </div>
                  <div className="flex justify-between items-center w-full">
                    <p className="text-base leading-4 text-gray-800">
                      Paid
                      <span className="bg-gray-200 p-1 text-xs font-medium leading-3 text-gray-800">
                        COD
                      </span>
                    </p>
                    <p className="text-base leading-4 text-gray-600">₹0</p>
                  </div>
                  <div className="flex justify-between items-center w-full">
                    <p className="text-base leading-4 text-gray-800">To Pay</p>
                    <p className="text-base leading-4 text-gray-600">
                      ₹{totalAmount}
                    </p>
                  </div>
                </div>
                <div className="flex justify-between items-center w-full">
                  <p className="text-base font-semibold leading-4 text-gray-800">
                    Total
                  </p>
                  <p className="text-base font-semibold leading-4 text-gray-600">
                    ₹{totalAmount}
                  </p>
                </div>
              </div>

              <div className="w-full mx-auto p-4 bg-white rounded-lg shadow-md">
                <h2 className="text-lg font-semibold mb-4">Actions</h2>
                <div className="grid grid-cols-1 w-full gap-2">
                  <button className="w-full flex items-center justify-center bg-green-800 text-white font-semibold py-2 px-4 rounded">
                    <FaBoxOpen className="mr-2" />
                    Accept
                  </button>
                  <button className="w-full flex items-center justify-center bg-red-600 text-white font-semibold py-2 px-4 rounded">
                    <FaTimes className="mr-2" />
                    Reject
                  </button>
                </div>
              </div>

              <div className="w-full mx-auto p-4 bg-white rounded-lg shadow-md">
                <h2 className="text-2xl font-bold mb-4 text-center">
                  Work Time Tracker
                </h2>

                <div className="mb-4">
                  <label className="block text-gray-700 font-semibold mb-2">
                    Arrival Time
                  </label>
                  <input
                    type="datetime-local"
                    value={arrivalTime}
                    onChange={(e) => setArrivalTime(e.target.value)}
                    className="w-full p-2 border border-gray-300 rounded-md"
                  />
                </div>

                <div className="mb-4">
                  <label className="block text-gray-700 font-semibold mb-2">
                    Work Start Time
                  </label>
                  <input
                    type="datetime-local"
                    value={startTime}
                    onChange={(e) => setStartTime(e.target.value)}
                    className="w-full p-2 border border-gray-300 rounded-md"
                  />
                </div>

                <div className="mb-4">
                  <label className="block text-gray-700 font-semibold mb-2">
                    Break Time (in minutes)
                  </label>
                  <input
                    type="number"
                    value={breakTime}
                    onChange={(e) =>
                      setBreakTime(parseInt(e.target.value) || 0)
                    }
                    className="w-full p-2 border border-gray-300 rounded-md"
                    placeholder="Enter break duration in minutes"
                  />
                </div>

                <div className="mb-4">
                  <label className="block text-gray-700 font-semibold mb-2">
                    Work Complete Time
                  </label>
                  <input
                    type="datetime-local"
                    value={workCompleteTime}
                    onChange={(e) => setWorkCompleteTime(e.target.value)}
                    className="w-full p-2 border border-gray-300 rounded-md"
                  />
                </div>

                <div className="mt-6">
                  <button
                    className="w-full bg-blue-500 text-white p-2 rounded-md font-semibold hover:bg-blue-600"
                    onClick={calculateTotalHours}
                  >
                    Calculate Total Work Hours
                  </button>
                </div>

                <div className="mt-4">
                  <p className="text-gray-700 font-semibold">
                    Total Work Hours: {calculateTotalHours()} hours
                  </p>
                </div>
              </div>
            </div>

            {orderStatus === "Accepted" && (
              <div className="w-full mx-auto flex flex-row gap-2 p-4 bg-white rounded-lg shadow-md">
                <button className="w-full flex items-center justify-center bg-black text-white font-semibold py-2 px-4 rounded">
                  <FaBoxOpen className="mr-2" />
                  Reached
                </button>
                <button className="w-full flex items-center justify-center bg-orange-600 text-white font-semibold py-2 px-4 rounded">
                  <FaTimes className="mr-2" />
                  Started Work
                </button>
              </div>
            )}
            {orderStatus === "Cancelled" && (
              <div className="w-full mx-auto flex flex-col gap-2 p-4 bg-white rounded-lg shadow-md">
                <textarea
                  className="w-full p-2 border border-gray-300 rounded"
                  placeholder="Enter rejection message"
                />
                <button className="w-full flex items-center justify-center bg-red-600 text-white font-semibold py-2 px-4 rounded">
                  <FaTimes className="mr-2" />
                  Submit
                </button>
              </div>
            )}
            {orderStatus === "Completed" && (
              <div className="w-full mx-auto flex flex-col gap-2 p-4 bg-white rounded-lg shadow-md">
                <p className="text-green-600 font-semibold">
                  Order Completed Successfully!
                </p>
              </div>
            )}

            <MachineCard
              id="GTM01456"
              name="Harvester"
              price="789"
              availabilityStatus="Available"
              industry="Forestry"
              imageUrl="https://d3odyy5st6a5um.cloudfront.net/sites/default/files/Stationary-Pump-SP1400_0.jpg"
            />

            <ProfileCard
              id="1111"
              name="Ravana"
              mobileNumber="+916374380946"
              district="cuddalore"
              profileImageUrl="https://static.vecteezy.com/system/resources/previews/024/066/189/non_2x/the-mighty-ravana-a-stunning-portrait-of-the-mythical-indian-demon-king-ai-generated-free-photo.jpg"
            />
          </div>
        </div>
      </div>
    </AdminLayout>
  );
};

export default ViewOrder;
