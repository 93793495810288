// App.js
import React, { lazy, Suspense } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Loader from "./Layouts/Loader.jsx";
import AdminAuth from "./Components/Auth/AdminAuth.jsx";
import RegisterAdminData from "./Components/Auth/RegisterAdminData.jsx";
import Transcations from "./Pages/Transcations.jsx";
import Home from "./Pages/Home.jsx";
import "./App.css"
import ViewEditMachine from "./Components/Manage/ViewEditMachine";
import ViewOrder from "./Components/Orders/ViewOrder.jsx";
import { AuthProvider } from "./config/AuthContext.js";
import AddBankAccount from "./Components/Profile/AddBankAccount.jsx";
import GenerateQRPage from "./Components/Helpers/QRGenerator.jsx";

// Admin pages
const Admins = lazy(() => import("./Pages/Admin"));
const AdminOrders = lazy(() => import("./Pages/AdminOrders"));
const AdminManage = lazy(() => import("./Pages/AdminManage"));
const AddMachine = lazy(() => import("./Components/Manage/AddMachine"));
const ViewMachine = lazy(() => import("./Components/Manage/ViewEditMachine"));
const AdminProfile = lazy(() => import("./Pages/AdminProfile"));

function App() {
  return (
    <BrowserRouter>
      <Suspense fallback={<Loader />}>
        <AuthProvider>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/auth" element={<AdminAuth />} />
            <Route path="/BecomeProvider" element={<RegisterAdminData />} />

            <Route path="/Home" element={<Admins />} />
            <Route path="/Orders" element={<AdminOrders />} />
            <Route path="/view-orders/:orderId" element={<ViewOrder />} />
            <Route path="/Profile" element={<AdminProfile />} />
            <Route path="/addMachine" element={<AddMachine />} />
            <Route path="/View/:machineId" element={<ViewEditMachine />} />
            <Route path="/Manage/ViewMachine" element={<ViewMachine />} />
            <Route path="/Manage" element={<AdminManage />} />
            <Route path="/Transcations" element={<Transcations />} />
            <Route path="/QR" element={<GenerateQRPage/>} />
            
            <Route path="/AddBank" element={<AddBankAccount/>} />

          </Routes>
        </AuthProvider>
      </Suspense>
    </BrowserRouter>
  );
}

export default App;
