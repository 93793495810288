import React from "react";
import AdminLayout from "../Layouts/AdminLayout";


const TransactionCard = ({ machineName, rentalPeriod, totalAmount, charges, paymentMethod, transactionDate }) => {
  return (
    <div className="bg-white border border-gray-200 rounded-lg shadow-md p-5 mb-4">
      <div className="flex justify-between items-center mb-3">
        <h2 className="text-xl font-bold text-gray-800">{machineName}</h2>
        <span className="text-sm font-medium text-blue-600 bg-blue-100 px-3 py-1 rounded-full">
          {paymentMethod}
        </span>
      </div>
      
      <div className="text-sm text-gray-600 mb-3">
        Rental Period: {rentalPeriod}
      </div>
      
      <div className="flex justify-between text-sm mb-3">
        <span className="text-gray-600">Rental:</span>
        <span className="font-medium">₹{(totalAmount - charges)}</span>
      </div>
      
      <div className="flex justify-between text-sm mb-3">
        <span className="text-gray-600">Charges:</span>
        <span className="font-medium">₹{charges}</span>
      </div>
      
      <div className="flex justify-between items-center text-lg font-bold mt-4 pt-3 border-t border-gray-200">
        <span>Earning:</span>
        <span className="text-green-600">₹{totalAmount.toFixed(2)}</span>
      </div>
      
      <div className="text-xs text-gray-500 mt-3 text-right">
        Transaction Date: {transactionDate}
      </div>
    </div>
  );
};

function Transcations() {

  const transaction = {
    machineName: "Excavator XL2000",
    rentalPeriod: "Oct 15, 2023 - Oct 20, 2023",
    totalAmount: 25000,
    charges: 2500,
    paymentMethod: "Credit Card",
    transactionDate: "Oct 14, 2023"
  };
  return (
    <AdminLayout>
      
      <div className="flex flex-col h-screen bg-gray-100">
        <header className="bg-white ">
          <div className="w-full  py-4 px-4 flex items-center justify-between">
            <h1 className="text-xl font-semibold text-gray-900">
              Transaction History
            </h1>
          </div>
        </header>
        <div className="flex-1 w-full mx-auto my-8 px-4">
        <div className="p-4 rounded-lg bg-white  shadow mx-auto">
      <div className="mb-4">
        <div className="flex justify-between mb-2">
          <div>
            <h2 className="text-gray-800 text-sm">Total Earning</h2>
            <p className="text-green-500 text-xl font-bold">₹3,179.99</p>
          </div>
          <div className="text-right">
            <h2 className="text-gray-800 text-sm">Charges</h2>
            <p className="text-green-600 text-xl font-bold">₹298.32</p>
          </div>
        </div>
        <div className="bg-gray-200 p-3 rounded-lg">
          <div className="flex justify-between items-center">
            <div>
              <h2 className="text-gray-800 text-sm">Total Earning</h2>
              <p className="text-gray-800 text-xs">= Total Earning - Charges</p>
            </div>
            <p className="text-green-500 text-xl font-bold">₹3,478.32</p>
          </div>
        </div>
      </div>
      <div className="relative">
        <input
          type="text"
          placeholder="Search "
          className="w-full bg-gray-200 text-gray-800 py-2 px-4 pr-10 rounded-lg focus:outline-none"
        />
        <span className="absolute right-3 top-2 text-gray-500">
          <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z" />
          </svg>
        </span>
      </div>
      <div className="flex mt-4 space-x-2">
        <button className="bg-green-600 text-white px-4 py-2 rounded-lg flex-grow">All</button>
        <button className="bg-gray-200 text-gray-900 px-4 py-2 rounded-lg flex-grow">Harvester</button>
        <button className="bg-gray-200 text-gray-900 px-4 py-2 rounded-lg flex-grow">Rotavator</button>
      
      </div>
    </div>
    <div className="my-2">

    <TransactionCard {...transaction} />
      </div>
        </div>
      </div>
    </AdminLayout>
  );
}

export default Transcations;
