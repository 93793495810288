import React from "react";
import logo from "../Asset/logo-growto-secondary.png";
import { Link, useNavigate } from "react-router-dom";
import { useEffect } from "react";
import {
  FaFile,
  FaFileAlt,
  FaIdCard,
  FaImages,
  FaMagic,
  FaRegMehBlank,
} from "react-icons/fa";
import Footer from "../Layouts/Footer";

function Home() {
  const navigate = useNavigate("/Home"); // Hook to control navigation

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) {
      navigate("/home");
    }
  }, []);
  return (
    <div>
      <section className="bg-white ">
        <nav className="bg-white  fixed w-full z-20 top-0 start-0 border-b border-gray-200 ">
          <div className="max-w-screen-xl flex flex-wrap items-center justify-between mx-auto p-4">
            <a className="flex flex-col items-center space-x-3 rtl:space-x-reverse">
              <div className="flex flex-col cursor-default items-start text-[20px] font-bold leading-none lg:text-[22px]">
                <img src={logo} alt="" className="w-26 h-8" />
                <span className="text-xs text-gray-800 ml-3">for Busniess</span>
              </div>
            </a>
            <div className="flex md:order-2 space-x-3 md:space-x-0 rtl:space-x-reverse">
              <Link to="/auth">
              <button
                type="button"
                className="text-white bg-green-700 hover:bg-green-800 focus:ring-4 focus:outline-none focus:ring-green-300 font-medium rounded-lg text-sm px-4 py-2 text-center dark:bg-green-600 dark:hover:bg-green-700 dark:focus:ring-green-800"
                >
                Register
              </button>
                </Link>
              <button
                data-collapse-toggle="navbar-sticky"
                type="button"
                className="inline-flex flex-col items-center p-2 w-10 h-10 justify-center text-sm text-gray-500 rounded-lg md:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200  "
                aria-controls="navbar-sticky"
                aria-expanded="false"
              >
                <span className="sr-only">Open main menu</span>
                <svg
                  className="w-5 h-5"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 17 14"
                >
                  <path
                    stroke="currentColor"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M1 1h15M1 7h15M1 13h15"
                  />
                </svg>
              </button>
            </div>
            <div
              className="items-center justify-between hidden w-full md:flex md:w-auto md:order-1"
              id="navbar-sticky"
            ></div>
          </div>
        </nav>
        <div className="py-8 px-4 mt-6 mx-auto max-w-screen-xl text-center lg:py-16 lg:px-12">
          <div
            className="inline-flex justify-between mt-8 items-center py-1 px-1 pr-4 mb-7 text-sm text-gray-700 bg-gray-200 rounded-full hover:bg-gray-300 dar"
            role="alert"
          >
            <span className="text-xs bg-green-600 rounded-full text-black px-4 py-1.5  mr-3">
              New
            </span>{" "}
            <span className="text-sm font-medium">
              GrowTo FB is out! See what's new
            </span>
            <svg
              className="ml-2 w-5 h-5"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                clip-rule="evenodd"
              ></path>
            </svg>
          </div>
          <h1 className="mb-4 text-4xl font-extrabold tracking-tight leading-none text-gray-900 md:text-5xl lg:text-6xl ">
            Partner with GrowTo at 0% commission for the 1st month!{" "}
          </h1>
          <p className="mb-8 text-lg font-normal text-gray-500 lg:text-xl sm:px-16 xl:px-48 ">
            And get ads worth INR 1500. Valid for new Machine partners in select
            cities.
          </p>
          <div className="flex flex-col mb-8 lg:mb-16 space-y-4 sm:flex-row sm:justify-center sm:space-y-0 sm:space-x-4">
            <Link
              to="/auth"
              className="inline-flex justify-center cursor-pointer items-center py-3 px-5 text-base font-medium text-center text-white rounded-lg bg-green-700 hover:bg-primary-800 focus:ring-4 focus:ring-primary-300 "
            >
              Register your Machine
              <svg
                className="ml-2 -mr-1 w-5 h-5"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z"
                  clip-rule="evenodd"
                ></path>
              </svg>
            </Link>
            <Link
              to="/auth"
              className="inline-flex justify-center cursor-pointer items-center py-3 px-5 text-base font-medium text-center text-gray-900 rounded-lg border border-gray-300 hover:bg-gray-100 focus:ring-4 focus:ring-gray-100 "
            >
              Login
            </Link>
          </div>
        </div>
      </section>
      <section className="relative mb-8 py-8 xl:mb-0 xl:py-12 lg:py-[92px] bg-[#191C1F] overflow-x-hidden">
        <div className="flex flex-col items-center justify-center mb-6">
          <span className="text-4xl text-white font-extrabold text-center">
            Get Started with Listing
          </span>
        </div>

        <div className="relative py-8 lg:px-6 xl:py-12 flex justify-center p-3">
          {/* Document Requirements Card */}
          <div className="bg-gray-800 p-8 rounded-lg shadow-lg max-w-2xl w-full mx-auto">
            <h2 className="text-2xl text-white font-bold mb-6 text-center">
              Prepare These Documents for a Smooth Signup
            </h2>
            <ul className="space-y-4 grid grid-cols-3 text-gray-300">
              <li className="flex flex-col items-center gap-3">
                <FaFileAlt className="text-2xl text-green-400" />
                FSSAI license copy
              </li>
              <li className="flex flex-col items-center gap-3">
                <FaIdCard className="text-2xl text-blue-400" />
                PAN card copy
              </li>
              <li className="flex flex-col items-center gap-3">
                <FaFile className="text-2xl text-yellow-400" />
                Regular GSTIN
              </li>
              <li className="flex flex-col items-center gap-3">
                <FaRegMehBlank className="text-2xl text-red-400" />
                Bank account details
              </li>
              <li className="flex flex-col items-center gap-3">
                <FaMagic className="text-2xl text-orange-400" />
                Your Machine Pricing
              </li>
              <li className="flex flex-col items-center gap-3">
                <FaImages className="text-2xl text-purple-400" />
                Your Machine Images
              </li>
            </ul>
          </div>
        </div>
      </section>
      <section className="">
        <div className="items-center max-w-screen-xl px-4 py-8 mx-auto lg:grid lg:grid-cols-4 lg:gap-16 xl:gap-24 lg:py-24 lg:px-6">
          <div className="col-span-2 mb-8">
            <p className="text-lg font-medium text-green-600 ">
              Trusted Over Tamil Nadu
            </p>
            <h2 className="mt-3 mb-4 text-3xl font-extrabold tracking-tight text-gray-900 md:text-3xl ">
              Trusted by over 100 users
            </h2>
            <p className="font-light text-gray-500 sm:text-xl ">
              Our rigorous security and compliance standards are at the heart of
              all we do. We work tirelessly to protect your business and ensure
              seamless operation of heavy machinery rentals.
            </p>
            <div className="pt-6 mt-6 space-y-4 border-t border-gray-200">
              <div>
                <a
                  href="#"
                  className="inline-flex flex-col items-center text-base font-medium text-green-600 hover:text-green-800 "
                >
                  Explore Rental Agreement
                  <svg
                    className="w-5 h-5 ml-1"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M12.293 5.293a1 1 0 011.414 0l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-2.293-2.293a1 1 0 010-1.414z"
                      clip-rule="evenodd"
                    ></path>
                  </svg>
                </a>
              </div>
              <div>
                <div className="inline-flex flex-col items-center text-base font-medium text-green-600 hover:text-green-800 ">
                  Visit the Our Safety Guidelines
                  <svg
                    className="w-5 h-5 ml-1"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M12.293 5.293a1 1 0 011.414 0l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-2.293-2.293a1 1 0 010-1.414z"
                      clip-rule="evenodd"
                    ></path>
                  </svg>
                </div>
              </div>
            </div>
          </div>
          <div className="col-span-2 space-y-8 md:grid md:grid-cols-2 md:gap-12 md:space-y-0">
            <div>
              <svg
                className="w-10 h-10 mb-2 text-green-600 md:w-12 md:h-12 "
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  d="M2 5a2 2 0 012-2h12a2 2 0 012 2v2a2 2 0 01-2 2H4a2 2 0 01-2-2V5zm14 1a1 1 0 11-2 0 1 1 0 012 0zM2 13a2 2 0 012-2h12a2 2 0 012 2v2a2 2 0 01-2 2H4a2 2 0 01-2-2v-2zm14 1a1 1 0 11-2 0 1 1 0 012 0z"
                  clip-rule="evenodd"
                ></path>
              </svg>
              <h3 className="mb-2 text-2xl font-bold ">99.99% uptime</h3>
              <p className="font-light text-gray-500 ">
                For seamless rental operations with minimal downtime.
              </p>
            </div>
            <div>
              <svg
                className="w-10 h-10 mb-2 text-green-600 md:w-12 md:h-12"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M9 6a3 3 0 11-6 0 3 3 0 016 0zM17 6a3 3 0 11-6 0 3 3 0 016 0zM12.93 17c.046-.327.07-.66.07-1a6.97 6.97 0 00-1.5-4.33A5 5 0 0119 16v1h-6.07zM6 11a5 5 0 015 5v1H1v-1a5 5 0 015-5z"></path>
              </svg>
              <h3 className="mb-2 text-2xl font-bold ">100+ Users</h3>
              <p className="font-light text-gray-500 ">
                Trusted by over 100 customers Tamil nadu.
              </p>
            </div>
            <div>
              <svg
                className="w-10 h-10 mb-2 text-green-600 md:w-12 md:h-12"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  d="M10 18a8 8 0 100-16 8 8 0 000 16zM4.332 8.027a6.012 6.012 0 011.912-2.706C6.512 5.73 6.974 6 7.5 6A1.5 1.5 0 019 7.5V8a2 2 0 004 0 2 2 0 011.523-1.943A5.977 5.977 0 0116 10c0 .34-.028.675-.083 1H15a2 2 0 00-2 2v2.197A5.973 5.973 0 0110 16v-2a2 2 0 00-2-2 2 2 0 01-2-2 2 2 0 00-1.668-1.973z"
                  clip-rule="evenodd"
                ></path>
              </svg>
              <h3 className="mb-2 text-2xl font-bold ">10+ Districts</h3>
              <p className="font-light text-gray-500 ">
                Serving customers in over 10 Districts.
              </p>
            </div>
            <div>
              <svg
                className="w-10 h-10 mb-2 text-green-600 md:w-12 md:h-12 "
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M3 1a1 1 0 000 2h1.22l.305 1.222a.997.997 0 00.01.042l1.358 5.43-.893.892C3.74 11.846 4.632 14 6.414 14H15a1 1 0 000-2H6.414l1-1H14a1 1 0 00.894-.553l3-6A1 1 0 0017 3H6.28l-.31-1.243A1 1 0 005 1H3zM16 16.5a1.5 1.5 0 11-3 0 1.5 1.5 0 013 0zM6.5 18a1.5 1.5 0 100-3 1.5 1.5 0 000 3z"></path>
              </svg>
              <h3 className="mb-2 text-2xl font-bold ">500+ Trancation</h3>
              <p className="font-light text-gray-500 ">
                Managing over 500 transactions every day.
              </p>
            </div>
          </div>
        </div>
      </section>
      <section className="relative mb-8 py-8 xl:mb-0 xl:py-12 lg:py-[92px] bg-[#1c4928] overflow-x-hidden">
        <div className="relative p-4 items-center justify-center flex flex-col">
          <h2 className="text-[34px]  font-bold text-white text-center ">
            Growto: Heavy Machinery for Business
          </h2>
          <p className="text-lg xl:text-[18px] text-gray-300 pb-6 xl:pb-9 xl:leading-[36px]">
            Increase efficiency and expand your reach with Growto's heavy
            machinery solutions. Tap into new markets with our extensive network
            and boost your brand visibility.
          </p>
          {/* Call-to-Action Buttons */}
          <div className="flex mt-5 xl:mt-0">
            <a
              className="block bg-green-900 text-white py-3 px-5 md:px-[40px] lg:text-lg rounded-full hover:scale-98 hover:bg-[#288A3F] transition-colors"
              href="#learn-more"
            >
              Learn More
            </a>
            <a
              className="block text-blue-500 py-3 px-5 md:px-[40px] lg:text-lg rounded-full flex flex-col items-center gap-2"
              href="#get-started"
            >
              <span>Get Started</span>
            </a>
          </div>
        </div>
      </section>
      <section className="relative mb-8 py-8 xl:mb-0 xl:py-12 lg:py-[92px] bg-white overflow-x-hidden">
        <div className="flex flex-col items-center justify-center">
          <span className="text-4xl font-extrabold text-center">
            How it works?
          </span>
        </div>
        <div className="relative p-4">
          {/* Step-by-step container */}
          <div className="flex flex-col gap-10 lg:flex-row lg:justify-between items-center">
            {/* Step 1 */}
            <div className="bg-gray-100  p-6 rounded-lg  border-gray-200 border  text-center flex flex-col justify-between w-full lg:w-1/3">
              <img
                src="https://via.placeholder.com/100" // Placeholder for step-related image
                alt="Step 1: Create your listing"
                className="mx-auto"
              />
              <h3 className="text-xl font-bold text-black mb-4">Step 1</h3>
              <p className="text-gray-900 font-semibold mb-6">
                Create your listing page on Growto. Showcase your heavy machines
                to attract more business.
              </p>
            </div>

            {/* Step 2 */}
            <div className="bg-gray-100  p-6 rounded-lg  border-gray-200 border  text-center flex flex-col justify-between w-full lg:w-1/3">
              <img
                src="https://via.placeholder.com/100" // Placeholder for step-related image
                alt="Step 1: Create your listing"
                className="mx-auto"
              />
              <h3 className="text-xl font-semibold text-black mb-4">Step 2</h3>
              <p className="text-gray-900 mb-6">
                Register for online transactions. Streamline payments and
                transactions with our secure platform.
              </p>
            </div>

            {/* Step 3 */}
            <div className="bg-gray-100  p-6 rounded-lg  border-gray-200 border  text-center flex flex-col justify-between w-full lg:w-1/3">
              <img
                src="https://via.placeholder.com/100" // Placeholder for step-related image
                alt="Step 1: Create your listing"
                className="mx-auto"
              />
              <h3 className="text-xl font-semibold text-black mb-4">Step 3</h3>
              <p className="text-gray-900 mb-6">
                Start receiving orders online. Use our partner app, web
                dashboard, or API integration for order management.
              </p>
            </div>
          </div>
        </div>
      </section>

      <div className="flex p-8 gap-6 flex-row">
      <div className="flex flex-col items-start justify-end gap-4 flex-1 border border-purple-300 bg-purple-50 rounded-2xl p-8 md:gap-4">
        <h3 className="text-center text-4xl font-extrabold leading-snug md:px-12 mt-12">
          Spot Signals
        </h3>
        <div className="text-center text-xl font-medium md:px-12 mt-12 mb-40">
          <span>
            Discover big breaking news likely <br /> to impact stock prices
          </span>
        </div>
        <div className="flex flex-col items-center justify-start  text-lg">
          
        </div>
      </div>

      <div className="flex flex-col items-start justify-end gap-4 flex-1 border border-green-400 bg-green-50 rounded-2xl p-8 md:gap-4">
        <h3 className="text-center text-4xl font-extrabold leading-snug md:px-12 mt-12">
          Broker Signals
        </h3>
        <div className="text-center text-xl font-medium md:px-12 mt-12 mb-40">
          Get buy and sell targets, along with recommendations from 10+ broker sources
        </div>
        <div className="flex flex-col items-center justify-start  text-lg">
         
        </div>
      </div>
    </div>
    
    <div className="flex flex-col items-start justify-end gap-4 flex-1 border border-purple-300 bg-purple-50 rounded-2xl  md:gap-4">
      <div className="flex-1">
        <h3 className="text-4xl font-extrabold leading-snug md:mt-0">
          Quant trading baskets with automated alerts
        </h3>
        <div className="mt-12 text-xl font-medium"></div>
        <div className="mt-32 flex flex-col gap-12 pb-60">
          <div className="text-lg font-semibold tracking-wide">
            🚀 Browse 10+ models on the app
          </div>
          <div className="text-lg font-semibold tracking-wide">
            ⚙️ Select and configure a model to generate signals
          </div>
          <div className="text-lg font-semibold tracking-wide">
            📊 View past performance data for your preferences
          </div>
          <div className="text-lg font-semibold tracking-wide">
            🔄 Trade buy and sell alerts with your broker through the app
          </div>
        </div>
      </div>
      <div className="flex flex-1 items-end justify-center p-8">
        <div className="flex flex-col items-center justify-start  text-lg">
          
        </div>
      </div>
    </div>
      <Footer/>
    </div>
  );
}

export default Home;
