// YourFormComponent.jsx

import { motion } from "framer-motion";
import Layouts from "../../Layouts/AdminLayout";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useState } from "react";
import { IoBusinessSharp } from "react-icons/io5";
import { FaUser } from "react-icons/fa";
import axios from "axios";
import { serverUrl } from "../../config/config";
import { addDoc, collection, doc, serverTimestamp, setDoc, updateDoc } from "firebase/firestore";
import { auth, db } from "../../config/FirebaseConfig";
import DrivingLicense from "./utils";

const RegisterAdminData = () => {
  const navigate = useNavigate();
  const [ownerType, setOwnerType] = useState("Individual");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [address, setAddress] = useState("");
  const [businessName, setBusinessName] = useState("");
  const [district, setDistrict] = useState("");
  const [pinCode, setPinCode] = useState("");
  const [gstNumber, setGstNumber] = useState("");
  const [selectedLocation, setSelectedLocation] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const [licenseNumber, setLicenseNumber] = useState("");
  const [licenseExpiryDate, setLicenseExpiryDate] = useState("");
  const [licenseIssuedState, setLicenseIssuedState] = useState("");
 
  const [bankName, setBankName] = useState("");
  const [accountNumber, setAccountNumber] = useState("");
  const [ifscCode, setIfscCode] = useState("");
  const [emergencyContactName, setEmergencyContactName] = useState("");
  const [emergencyContactRelationship, setEmergencyContactRelationship] = useState("");
  const [emergencyContactPhoneNumber, setEmergencyContactPhoneNumber] = useState("");
  const districts = [
    "Chennai",
    "Coimbatore",
    "Cuddalore",
    "Dharmapuri",
    "Dindigul",
    "Erode",
    "Kanchipuram",
    "Kanyakumari",
    "Karur",
    "Krishnagiri",
    "Madurai",
    "Nagapattinam",
    "Namakkal",
    "Nilgiris",
    "Perambalur",
    "Pudukkottai",
    "Ramanathapuram",
    "Salem",
    "Sivaganga",
    "Thanjavur",
    "Theni",
    "Thoothukudi",
    "Tiruchirappalli",
    "Tirunelveli",
    "Tiruppur",
    "Tiruvallur",
    "Tiruvannamalai",
    "Tiruvarur",
    "Vellore",
    "Viluppuram",
    "Virudhunagar",
  ];
  
  const ph = searchParams.get("ph");

  const handleSelection = (type) => {
    setOwnerType(type);
  };

  const handleLocationChange = (e) => {
    setSelectedLocation(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    // location: {
      //   latitude, // Current latitude of the driver
      //   longitude, // Current longitude of the driver
      //   lastUpdated: serverTimestamp(), // Last location update time
      // },
      // availability: {
      //   isActive: true, // Whether the driver is active and available for rides
      //   isOnline: true, // Whether the driver is currently online
      //   isOnRide: false, // Whether the driver is currently on a ride
      // },
      // rating: {
      //   averageRating: 0, // Average rating given by users
      //   totalRides: 0, // Total number of rides completed
      //   totalRatings: 0, // Total number of ratings received
      // },
      // documents: {
      //   aadharCard, 
      //   licence, 
      // },
    const formData = {
      name,
      email,
      ownerType,
      mobileNumber: ph,
      address: {
        street: address,
        pinCode,
      },
      driverLicense: {
        licenseNumber,
        licenseExpiryDate,
        licenseIssuedState,
      },
      status: "offline",
      availability: {
        isOnline: true,
        isOffline: false,
      },
      rating: {
        averageRating: 0,
        totalRides: 0,
        totalRatings: 0,
      },
      bankDetails: {
        bankName,
        accountNumber,
        ifscCode,
      },
      emergencyContact: {
        name: emergencyContactName,
        relationship: emergencyContactRelationship,
        phoneNumber: emergencyContactPhoneNumber,
      },
      createdAt: serverTimestamp(),
      updatedAt: serverTimestamp(),
    };
  
    if (ownerType === 'Business') {
      formData.businessDetails = {
        businessName,
        gstNumber,
        businessAddress: address,
      };
    }
  
    const user = auth.currentUser;
    const userId = user.uid;
  
    try {
      // Store form data in Firestore
      await setDoc(doc(db, 'Providers', userId), formData);
  
      setIsLoading(false);
      navigate("/home");
    } catch (error) {
      console.error("Error submitting data:", error);
      setIsLoading(false);
    }
  };
  return (
    <Layouts>
      <motion.div
        initial={{ opacity: 0, y: -50 }}
        animate={{ opacity: 1, y: 0 }}
        exit={{ opacity: 0, y: -50 }}
        transition={{ duration: 0.5 }}
        className="max-w-md mx-auto mt-8 p-8 h-auto   bg-white "
      >
        <form className="h-full overflow-y-auto" onSubmit={handleSubmit}>
          {/* <span className="text-xl text-center font-bold text-black">
          Complete Your Registration
        </span> */}
          <div className="flex justify-around ">
            <div
              className={`p-2 border-2 border-dashed rounded-lg cursor-pointer ${
                ownerType === "Individual"
                  ? "border-green-500 bg-green-200 text-green-700"
                  : "border-gray-400 text-gray-700"
              }`}
              onClick={() => handleSelection("Individual")}
            >
              <FaUser
                className={`mx-auto text-2xl ${
                  ownerType === "Individual"
                    ? "text-green-700"
                    : "text-gray-700"
                }`}
              />
              <p className="text-center mt-1">Individual</p>
            </div>

            <div
              className={`p-2 border-2 border-dashed rounded-lg cursor-pointer ${
                ownerType === "Business"
                  ? "border-green-500 bg-green-200 text-green-700"
                  : "border-gray-400 text-gray-700"
              }`}
              onClick={() => handleSelection("Business")}
            >
              <IoBusinessSharp
                className={`mx-auto text-2xl ${
                  ownerType === "Business" ? "text-green-700" : "text-gray-700"
                }`}
              />
              <p className="text-center mt-1">Business</p>
            </div>
          </div>

          <div className="mb-4">
            <label
              htmlFor="name"
              className="block text-sm font-medium text-gray-600"
            >
              Name
            </label>
            <input
              type="text"
              id="name"
              name="name"
              value={name}
              onChange={(e) => setName(e.target.value)}
              className="mt-1 p-2 w-full border border-gray-400 bg-[#f2f3f7] rounded-lg"
              required
            />
          </div>

          <div className="mb-4">
            <label
              htmlFor="email"
              className="block text-sm font-medium text-gray-600"
            >
              Email
            </label>
            <input
              type="email"
              id="email"
              name="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              className="mt-1 p-2 w-full border border-gray-400 bg-[#f2f3f7] rounded-lg"
            />
          </div>

          <div className="mb-4">
            <label
              htmlFor="address"
              className="block text-sm font-medium text-gray-600"
            >
              Address
            </label>
            <textarea
              id="address"
              name="address"
              rows="3"
              value={address}
              onChange={(e) => setAddress(e.target.value)}
              className="mt-1 p-2 w-full border border-gray-400 bg-[#f2f3f7] rounded-lg"
              required
            ></textarea>
          </div>
          <div className="mb-4">
           <label htmlFor="location-select" className="block text-sm font-medium text-gray-700">
        Select your district:
      </label>
      <select
        id="location-select"
        value={selectedLocation}
        onChange={handleLocationChange}
        className="mt-1 block w-full p-2 bg-white border border-gray-300 rounded-md shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
      >
        <option value="">--Please choose an option--</option>
        {districts.map((location) => (
          <option key={location} value={location}>
            {location}
          </option>
        ))}
      </select>
          </div>

          <div className="mb-4">
            <label
              htmlFor="pinCode"
              className="block text-sm font-medium text-gray-600"
            >
              Pin Code
            </label>
            <input
              type="text"
              id="pinCode"
              name="pinCode"
              value={pinCode}
              onChange={(e) => setPinCode(e.target.value)}
              className="mt-1 p-2 w-full border border-gray-400 bg-[#f2f3f7] rounded-lg"
              required
            />
          </div>
          {ownerType === "Business" && (
            <>
              <div className="mb-4">
                <label
                  htmlFor="businessName"
                  className="block text-sm font-medium text-gray-600"
                >
                  Business Name
                </label>
                <input
                  type="text"
                  id="businessName"
                  name="businessName"
                  value={businessName}
                  onChange={(e) => setBusinessName(e.target.value)}
                  className="mt-1 p-2 w-full border border-gray-400 bg-[#f2f3f7] rounded-lg"
                  required
                />
              </div>

              <div className="mb-4">
                <label
                  htmlFor="gstNumber"
                  className="block text-sm font-medium text-gray-600"
                >
                  GST Number
                </label>
                <input
                  type="text"
                  id="gstNumber"
                  name="gstNumber"
                  value={gstNumber}
                  onChange={(e) => setGstNumber(e.target.value)}
                  className="mt-1 p-2 w-full border border-gray-400 bg-[#f2f3f7] rounded-lg"
                  required
                />
              </div>
            </>
          )}
<div className="mb-4">
  <label
    htmlFor="licenseNumber"
    className="block text-sm font-medium text-gray-600"
  >
    License Number
  </label>
  <input
    type="text"
    id="licenseNumber"
    name="licenseNumber"
    value={licenseNumber}
    onChange={(e) => setLicenseNumber(e.target.value)}
    className="mt-1 p-2 w-full border border-gray-400 bg-[#f2f3f7] rounded-lg"
    required
  />
</div>

<div className="mb-4">
  <label
    htmlFor="licenseExpiryDate"
    className="block text-sm font-medium text-gray-600"
  >
    License Expiry Date
  </label>
  <input
    type="date"
    id="licenseExpiryDate"
    name="licenseExpiryDate"
    value={licenseExpiryDate}
    onChange={(e) => setLicenseExpiryDate(e.target.value)}
    className="mt-1 p-2 w-full border border-gray-400 bg-[#f2f3f7] rounded-lg"
    required
  />
</div>

<div className="mb-4">
  <label
    htmlFor="licenseIssuedState"
    className="block text-sm font-medium text-gray-600"
  >
    License Issued State
  </label>
  <input
    type="text"
    id="licenseIssuedState"
    name="licenseIssuedState"
    value={licenseIssuedState}
    onChange={(e) => setLicenseIssuedState(e.target.value)}
    className="mt-1 p-2 w-full border border-gray-400 bg-[#f2f3f7] rounded-lg"
    required
  />
</div>


<div className="mb-4">
  <label
    htmlFor="bankName"
    className="block text-sm font-medium text-gray-600"
  >
    Bank Name
  </label>
  <input
    type="text"
    id="bankName"
    name="bankName"
    value={bankName}
    onChange={(e) => setBankName(e.target.value)}
    className="mt-1 p-2 w-full border border-gray-400 bg-[#f2f3f7] rounded-lg"
    required
  />
</div>

<div className="mb-4">
  <label
    htmlFor="accountNumber"
    className="block text-sm font-medium text-gray-600"
  >
    Account Number
  </label>
  <input
    type="text"
    id="accountNumber"
    name="accountNumber"
    value={accountNumber}
    onChange={(e) => setAccountNumber(e.target.value)}
    className="mt-1 p-2 w-full border border-gray-400 bg-[#f2f3f7] rounded-lg"
    required
  />
</div>

<div className="mb-4">
  <label
    htmlFor="ifscCode"
    className="block text-sm font-medium text-gray-600"
  >
    IFSC Code
  </label>
  <input
    type="text"
    id="ifscCode"
    name="ifscCode"
    value={ifscCode}
    onChange={(e) => setIfscCode(e.target.value)}
    className="mt-1 p-2 w-full border border-gray-400 bg-[#f2f3f7] rounded-lg"
    required
  />
</div>

<div className="mb-4">
  <label
    htmlFor="emergencyContactName"
    className="block text-sm font-medium text-gray-600"
  >
    Emergency Contact Name
  </label>
  <input
    type="text"
    id="emergencyContactName"
    name="emergencyContactName"
    value={emergencyContactName}
    onChange={(e) => setEmergencyContactName(e.target.value)}
    className="mt-1 p-2 w-full border border-gray-400 bg-[#f2f3f7] rounded-lg"
    required
  />
</div>

<div className="mb-4">
  <label
    htmlFor="emergencyContactRelationship"
    className="block text-sm font-medium text-gray-600"
  >
    Emergency Contact Relationship
  </label>
  <input
    type="text"
    id="emergencyContactRelationship"
    name="emergencyContactRelationship"
    value={emergencyContactRelationship}
    onChange={(e) => setEmergencyContactRelationship(e.target.value)}
    className="mt-1 p-2 w-full border border-gray-400 bg-[#f2f3f7] rounded-lg"
    required
  />
</div>

<div className="mb-4">
  <label
    htmlFor="emergencyContactPhoneNumber"
    className="block text-sm font-medium text-gray-600"
  >
    Emergency Contact Phone Number
  </label>
  <input
    type="text"
    id="emergencyContactPhoneNumber"
    name="emergencyContactPhoneNumber"
    value={emergencyContactPhoneNumber}
    onChange={(e) => setEmergencyContactPhoneNumber(e.target.value)}
    className="mt-1 p-2 w-full border border-gray-400 bg-[#f2f3f7] rounded-lg"
    required
  />
</div>


          <button
            type="submit"
            className="bg-green-500 text-white px-4 py-3 rounded-md hover:bg-green-600 w-full"
            disabled={isLoading}
          >
            {isLoading ? "Submitting..." : "Submit"}
          </button>
        </form>
      </motion.div>
    </Layouts>
  );
};

export default RegisterAdminData;
