import React, { useState } from "react";
import { FaPhone, FaPhoneAlt, FaTripadvisor } from "react-icons/fa";
import { Link } from "react-router-dom";

const MachineCard = ({
  id,
  name,
  price,
  imageUrl,
  industry,
  availabilityStatus,
}) => {
  const [isDetailsVisible, setIsDetailsVisible] = useState(false);

  const toggleDetailsVisibility = () => {
    setIsDetailsVisible(!isDetailsVisible);
  };

  return (
    <div
      className={`bg-white rounded-lg shadow-md overflow-hidden border border-gray-200 cursor-pointer`}
    >
      <div className="flex">
        <div className="w-1/3 relative">
          <img
            src={imageUrl}
            alt={name}
            className="w-full h-full object-cover"
          />
          <div className="absolute top-2 left-2 bg-black bg-opacity-60 text-white text-xs font-medium px-2 py-1 rounded-full">
            {industry}
          </div>
        </div>
        <div className="w-2/3 p-4 flex flex-col justify-between">
          <div>
            <div className="flex justify-between items-start mb-2">
              <h3 className="text-lg font-semibold text-gray-800">{name}</h3>
              <div
                className={`text-xs font-medium px-2 py-1 rounded-full ${
                  availabilityStatus === "Available"
                    ? "bg-green-100 text-green-800"
                    : "bg-yellow-100 text-yellow-800"
                }`}
              >
                {availabilityStatus}
              </div>
            </div>
          </div>

          <label
            htmlFor={`details-toggle-${id}`}
            onClick={toggleDetailsVisibility}
            className="relative h-8 w-14 cursor-pointer [-webkit-tap-highlight-color:_transparent]"
          >
            <input
              type="checkbox"
              id={`details-toggle-${id}`}
              className="peer sr-only [&:checked_+_span_svg[data-checked-icon]]:block [&:checked_+_span_svg[data-unchecked-icon]]:hidden"
            />
            <span className="absolute inset-y-0 start-0 z-10 m-1 inline-flex h-6 w-6 items-center justify-center rounded-full bg-white text-gray-400 transition-all peer-checked:start-6 peer-checked:text-green-600">
              <svg
                data-unchecked-icon
                className={`h-4 w-4 ${!isDetailsVisible ? "block" : "hidden"}`}
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fillRule="evenodd"
                  d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                  clipRule="evenodd"
                />
              </svg>
              <svg
                data-checked-icon
                xmlns="http://www.w3.org/2000/svg"
                className={`h-4 w-4 ${isDetailsVisible ? "block" : "hidden"}`}
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fillRule="evenodd"
                  d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                  clipRule="evenodd"
                />
              </svg>
            </span>
            <span className="absolute inset-0 rounded-full bg-gray-300 transition peer-checked:bg-green-500"></span>
          </label>
          <div className="flex justify-between items-center mt-3">
            <p className="text-lg font-bold text-gray-900">
              ₹{price}
              <span className="text-sm font-normal text-gray-600">/hour</span>
            </p>
            <Link
              to={`/View/${id}`}
              className="bg-[#1c4928] hover:bg-green-800 text-white text-sm font-medium py-1 px-3 rounded-lg"
            >
              View Details
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export const ProfileCard = ({
  id,
  name,
  mobileNumber,
  district,
  profileImageUrl,
}) => {
  return (
    <div className="bg-white rounded-lg shadow-md overflow-hidden border border-gray-200 cursor-pointer max-w-md mx-auto">
      <div className="flex">
        <div className="w-1/3 relative">
          <img
            src={profileImageUrl}
            alt={name}
            className="w-full h-full  object-cover"
          />
        </div>
        <div className="w-2/3 p-4 flex flex-col justify-between">
          <div>
            <div className="flex justify-between items-start mb-2">
              <div className="flex flex-row">
              <h3 className="text-lg font-semibold text-gray-800 ml-2">{name}</h3>
              <svg class="my-auto ml-2 h-5 fill-blue-400" 
           version="1.1" width="24" height="24" viewBox="0 0 24 24">
          <path
            d="M23,12L20.56,9.22L20.9,5.54L17.29,4.72L15.4,1.54L12,3L8.6,1.54L6.71,4.72L3.1,5.53L3.44,9.21L1,12L3.44,14.78L3.1,18.47L6.71,19.29L8.6,22.47L12,21L15.4,22.46L17.29,19.28L20.9,18.46L20.56,14.78L23,12M10,17L6,13L7.41,11.59L10,14.17L16.59,7.58L18,9L10,17Z" />
          </svg> 
              </div>
              <div
                className={`text-xs font-medium px-2 py-1 rounded-full text-green-800 bg-green-200`}
              >
               {district}
              </div>
            </div>
            
          </div>
          <div class=" flex flex-row items-center space-x-5">
            <div class="flex p-2 flex-col items-center justify-center rounded-md border border-dashed border-gray-200  hover:border-gray-400/80">
              <div class="flex flex-col items-center justify-center">
              <div class="mt-2 text-sm text-gray-400">Joined On</div>
                <span class="font-bold text-gray-600">23 June 2023</span>
              </div>
            </div>
            <div class="flex p-2 flex-col items-center justify-center rounded-md border border-dashed border-gray-200  hover:border-gray-400/80">
              <div class="flex flex-col items-center justify-center">
              <div class="mt-2 text-sm text-gray-400">Rented</div>
                <span class="font-bold text-gray-600"> 45 </span>
              </div>
            </div>
          </div>

          <div className="flex justify-between items-center mt-3">
          <a href={`tel:+${mobileNumber}`} className="bg-[#1c4928] hover:bg-green-800 text-white text-sm font-medium py-1 px-3 rounded-lg"
          >
            <FaPhone/>
          </a>
           
          </div>
        </div>
      </div>
    </div>
  );
};
export { MachineCard };
