import React, { useState, useEffect, useRef, useCallback } from "react";
import { useParams } from "react-router-dom";
import AdminLayout from "../../Layouts/AdminLayout";
import { doc, getDoc, updateDoc } from "firebase/firestore";
import { db } from "../../config/FirebaseConfig";
import { geohashForLocation } from "geofire-common";
import { FaLocationArrow, FaMoneyBill } from "react-icons/fa";
import {
  GoogleMap,
  useJsApiLoader,
  Autocomplete,
  Marker,
} from "@react-google-maps/api";

const libraries = ["places"];
const mapContainerStyle = {
  height: "400px",
  width: "100%",
  
};
const center = {
  lat: 12.9716,
  lng: 77.5946,
};

function ViewEditMachine() {
  const { machineId } = useParams();
  const [machine, setMachine] = useState(null);
  const [loading, setLoading] = useState(true);
  const [message, setMessage] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  const [suggestions, setSuggestions] = useState([]);
  const [currentLocation, setCurrentLocation] = useState(null);
  const autocompleteRef = useRef(null);
  const mapRef = useRef(null);
  const [selectedLocation, setSelectedLocation] = useState(null);

  const { isLoaded, loadError } = useJsApiLoader({
    googleMapsApiKey: "AIzaSyA-Ojj5pyuB44-I7ZZxOUJgsnDTkaq9wfw",
    libraries,
  });

  useEffect(() => {
    async function fetchMachine() {
      try {
        const machineDoc = await getDoc(doc(db, "Machines", machineId));
        if (machineDoc.exists()) {
          setMachine(machineDoc.data());
        } else {
          setMessage("Machine not found.");
        }
      } catch (error) {
        console.error("Error fetching machine:", error);
        setMessage("Error fetching machine.");
      } finally {
        setLoading(false);
      }
    }
    fetchMachine();
  }, [machineId]);

  useEffect(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const currentPos = {
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          };
          setCurrentLocation(currentPos);
        },
        (error) => {
          console.error("Error getting current location:", error);
        }
      );
    } else {
      console.log("Geolocation is not supported by this browser.");
    }
  }, []);

  const handleUpdateLocation = async () => {
    try {
      if (selectedLocation) {
        await updateDoc(doc(db, "Machines", machineId), {
          location: selectedLocation,
        });
        setMachine((prev) => ({
          ...prev,
          location: selectedLocation,
        }));
        setMessage("Location updated successfully.");
      } else {
        setMessage("Please select a location on the map.");
      }
    } catch (error) {
      console.error("Error updating location:", error);
      setMessage("Error updating location.");
    }
  };

  const handleUpdatePrice = async (e) => {
    const newPrice = e.target.value;
    try {
      await updateDoc(doc(db, "Machines", machineId), {
        price: newPrice,
      });
      setMachine((prev) => ({
        ...prev,
        price: newPrice,
      }));
      setMessage("Price updated successfully.");
    } catch (error) {
      console.error("Error updating price:", error);
      setMessage("Error updating price.");
    }
  };

  const handleToggleAvailability = async () => {
    try {
      await updateDoc(doc(db, "Machines", machineId), {
        availabilityStatus:
          machine.availabilityStatus === "Available"
            ? "Maintenance"
            : "Available",
      });
      setMachine((prev) => ({
        ...prev,
        availabilityStatus:
          prev.availabilityStatus === "Available" ? "Maintenance" : "Available",
      }));
      setMessage("Availability status updated successfully.");
    } catch (error) {
      console.error("Error updating availability status:", error);
      setMessage("Error updating availability status.");
    }
  };

  const onPlaceChanged = () => {
    if (autocompleteRef.current !== null) {
      const place = autocompleteRef.current.getPlace();
      if (place.formatted_address) {
        setSelectedLocation({
          lat: place.geometry.location.lat(),
          lng: place.geometry.location.lng(),
          geohash: geohashForLocation([
            place.geometry.location.lat(),
            place.geometry.location.lng(),
          ]),
        });
        setSearchQuery(place.formatted_address);
      }
    } else {
      console.log("Autocomplete is not loaded yet!");
    }
  };

  const handleSearchChange = (e) => {
    const query = e.target.value;
    setSearchQuery(query);

    if (query.trim() === "") {
      setSuggestions([]);
      return;
    }

    if (autocompleteRef.current) {
      const service = new window.google.maps.places.AutocompleteService();
      service.getPlacePredictions({ input: query }, (predictions, status) => {
        if (status === window.google.maps.places.PlacesServiceStatus.OK) {
          setSuggestions(predictions);
        } else {
          setSuggestions([]);
        }
      });
    }
  };

  const handleSelectSuggestion = (placeId) => {
    console.log("Selected place:", placeId);
    setSuggestions([]);
    setSearchQuery("");
  };

  if (loading) return <div>Loading...</div>;

  if (loadError) return <div>Error loading maps</div>;

  if (!isLoaded) return <div>Loading Maps</div>;
  return (
    <AdminLayout>
      {machine && (
        <div className="mt-4 p-6 bg-white shadow-lg rounded-lg">
          <div className="flex flex-row justify-between items-center">
            <h2 className="text-2xl font-bold mb-2">{machine.name}</h2>
            <div className="relative inline-block w-10 align-middle select-none transition duration-200 ease-in">
              <input
                type="checkbox"
                checked={machine.availabilityStatus === "Available"}
                onChange={handleToggleAvailability}
                className="toggle-checkbox absolute block w-6 h-6 rounded-full bg-white border-4 appearance-none cursor-pointer transition duration-300"
              />
              <label className="toggle-label block overflow-hidden h-6 rounded-full bg-gray-300 cursor-pointer transition duration-300"></label>
            </div>
          </div>
          <div className="mb-6">
            <div className="flex items-center justify-center md:justify-start">
              <img
                src={machine.imageUrl}
                alt={machine.name}
                className="rounded-lg w-full h-32 "
              />
            </div>
            <div className="flex flex-col gap-4 mt-4  w-full">
              <div className="flex flex-row  w-full gap-3">
                <div className="w-full  ">
                  <label
                    htmlFor="name"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Name
                  </label>
                  <p className="mt-1 block w-full px-4 py-2 bg-gray-200 text-black border border-gray-300 rounded-md">
                    {machine.name}
                  </p>
                </div>
                <div className="w-full  ">
                  <label
                    htmlFor="category"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Category
                  </label>
                  <p className="mt-1 block w-full px-4 py-2 bg-gray-200 text-black border border-gray-300 rounded-md">
                    {machine.categories}
                  </p>
                </div>
              </div>
              <div className="flex flex-row  w-full gap-3">
                <div className="w-full  sm:pr-2">
                  <label
                    htmlFor="price"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Price
                  </label>
                  <p className="mt-1 block w-full px-4 py-2 bg-gray-200 text-black border border-gray-300 rounded-md">
                    ₹{machine.price}
                  </p>
                </div>
                <div className="w-full  sm:pr-2">
                  <label
                    htmlFor="ownerName"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Owner
                  </label>
                  <p className="mt-1 block w-full px-4 py-2 bg-gray-200 text-black border border-gray-300 rounded-md">
                    {machine.ownerName}
                  </p>
                </div>
              </div>
              <div className="flex flex-row  w-full gap-3">
                <div className="w-full  ">
                  <label
                    htmlFor="district"
                    className="block text-sm font-medium text-gray-700"
                  >
                    District
                  </label>
                  <p className="mt-1 block w-full px-4 py-2 bg-gray-200 text-black border border-gray-300 rounded-md">
                    {machine.district}
                  </p>
                </div>
                <div className="w-full  ">
                  <label
                    htmlFor="Industry"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Industry
                  </label>
                  <p className="mt-1 block w-full px-4 py-2 bg-gray-200 text-black border border-gray-300 rounded-md">
                    {machine.industry}
                  </p>
                </div>
              </div>
            </div>
          </div>
          {message && <p>{message}</p>}
          <div className="flex flex-col gap-4">
            <div className="w-full sm:w-1/2 pr-0 sm:pr-2 mt-4 sm:mt-0">
              <label
                htmlFor="location"
                className="block text-sm font-medium text-gray-700"
              >
                Location
              </label>
              <div className="relative mb-3">
                <Autocomplete
                  onLoad={(autocomplete) => {
                    autocompleteRef.current = autocomplete;
                  }}
                  onPlaceChanged={onPlaceChanged}
                >
                  <input
                    type="text"
                    value={searchQuery}
                    onChange={handleSearchChange}
                    placeholder="Where?"
                    className="mt-1 block w-full px-4 py-2 bg-white text-black border border-gray-300 rounded-md"
                  />
                </Autocomplete>
                {suggestions.length > 0 && (
                  <ul className="absolute mt-2 bg-white border border-gray-300 rounded-md w-full max-h-60 overflow-auto z-50">
                    {suggestions.map((suggestion) => (
                      <div
                        key={suggestion.place_id}
                        onClick={() =>
                          handleSelectSuggestion(suggestion.place_id)
                        }
                        className="flex items-center justify-between p-2 hover:bg-gray-100 cursor-pointer"
                      >
                        <div className="flex items-center">
                          <div className="w-10 h-10 bg-green-100 rounded-full flex items-center justify-center mr-3">
                            <FaLocationArrow className="w-6 h-6 text-green-500" />
                          </div>
                          <div>
                            <p className="font-semibold">
                              {suggestion.description}
                            </p>
                          </div>
                        </div>
                      </div>
                    ))}
                  </ul>
                )}
              </div>
              <GoogleMap
                id="map"
                mapContainerStyle={mapContainerStyle}
                zoom={14}
                center={currentLocation || center}
                onLoad={(map) => {
                  mapRef.current = map;
                }}
              >
                {selectedLocation && (
                  <Marker
                    position={{
                      lat: selectedLocation.lat,
                      lng: selectedLocation.lng,
                    }}
                  />
                )}
              </GoogleMap>
            </div>

            <div className="flex flex-col gap-4 bg-white border border-gray-300 shadow-lg rounded-lg p-8 w-full">
              <div className="mb-6">
                <label
                  htmlFor="price"
                  className="block text-sm font-medium text-gray-700 mb-2"
                >
                  Update Price
                </label>
                <div className="flex items-center space-x-2">
                  <FaMoneyBill />
                  <input
                    type="number"
                    id="price"
                    className="bg-gray-100 border border-gray-300 rounded-lg px-4 py-2 w-full focus:outline-none focus:ring-2 focus:ring-green-500 focus:border-transparent transition duration-300"
                  />
                </div>
              </div>

              <button
                onClick={handleUpdatePrice}
                className="flex items-center flex-row justify-center bg-green-500 text-white px-4 py-2 rounded-lg shadow hover:bg-green-600 transition duration-300"
              >
                <FaMoneyBill />
                <span className="ml-2">Update Price</span>
              </button>
            </div>
          </div>
        </div>
      )}
    </AdminLayout>
  );
}

export default ViewEditMachine;
