import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { AiFillHome, AiFillSetting } from "react-icons/ai";
import { PiPottedPlantDuotone } from "react-icons/pi";
import { MdOutlineManageAccounts } from "react-icons/md";
import { BsFillBoxSeamFill } from 'react-icons/bs';
import { FaHistory } from 'react-icons/fa';
import { MdClose } from 'react-icons/md'; // Import the close icon

const menuItems = [
  { path: "/home", icon: AiFillHome, label: "Home" },
  { path: "/Orders", icon: BsFillBoxSeamFill, label: "Orders" },
  { path: "/Manage", icon: MdOutlineManageAccounts, label: "Manage" },
  { path: "/Transcations", icon: PiPottedPlantDuotone, label: "Transcation" },
  { path: "/Profile", icon: AiFillSetting, label: "Profile" },
];

function AdminBottomNavigator() {
  const [isDownloadVisible, setDownloadVisible] = useState(true); // State to control visibility
  const location = useLocation();
  const pathname = location.pathname;

  const isActive = (path) => {
    return pathname === path;
  };

  const openPlayStore = () => {
    window.open("https://play.google.com/store/apps/details?id=com.growto_biz.app", "_blank");
  };

  return (
    <div>
      <nav className="fixed bottom-0 left-0 z-20 w-full shadow-lg lg:hidden">
        {isDownloadVisible && ( // Conditional rendering based on state
          <div className="bg-white p-1.5 text-center border-y-[1px] border-gray-200 flex justify-between items-center">
            <div></div>
            <span className=" cursor-pointer hover:text-green-800 text-gray-700 font-semibold" onClick={openPlayStore}>
              Download the App
            </span>
            <MdClose 
              size={24} 
              color="gray" 
              className="cursor-pointer" 
              onClick={() => setDownloadVisible(false)} // Close icon click handler
            />
          </div>
        )}
        <div className="px-4 py-1 bg-white flex justify-between items-center p-1">
          {menuItems.map(({ path, icon: Icon, label }) => (
            <Link
              key={path}
              to={path}
              className={`flex flex-col items-center cursor-pointer ${isActive(path) ? "" : ""}`}
            >
              <Icon size={24} color={isActive(path) ? "green" : "gray"} fill={isActive(path) ? "green" : "gray"} />
              <span className={`text-xs mt-1 ${isActive(path) ? "text-green-700" : "text-gray-500"}`}>
                {label}
              </span>
            </Link>
          ))}
        </div>
      </nav>
    </div>
  );
}

export default AdminBottomNavigator;
