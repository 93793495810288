import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import logo from "../Asset/logo-growto-secondary.png";

import { CgProfile } from "react-icons/cg";
import { BiSolidBellRing } from "react-icons/bi";
import { isAuth } from "../Hooks/context/Routes/isAuth";
import { PiQrCode, PiShareNetworkDuotone } from "react-icons/pi";
import { HiSearch } from "react-icons/hi";
import { IoChevronBackOutline } from "react-icons/io5";
import { useNavigate } from "react-router-dom/dist/umd/react-router-dom.development";
import { auth, db } from "../config/FirebaseConfig";
import { doc, getDoc, setDoc, updateDoc } from "firebase/firestore";
import { MdOutlineQrCodeScanner } from "react-icons/md";

function AdminHeader() {
  const [driverStatus, setDriverStatus] = useState("Online"); // Assuming driverStatus management
  const location = useLocation();
  const navigate = useNavigate();

  // Function to handle going back
  const handleGoBack = () => {
    navigate(-1);
  };

 // Function to fetch and set user status on component mount
 useEffect(() => {
  const fetchUserStatus = async () => {
    try {
      const user = auth.currentUser;
      if (user) {
        const userId = user.uid;
        const userRef = doc(db, "Providers", userId);
        const docSnap = await getDoc(userRef);

        if (docSnap.exists()) {
          const userStatus = docSnap.data().status;
          setDriverStatus(userStatus); // Set the initial status from Firestore
        } else {
          console.log("No such document!");
        }
      } else {
        console.log("No user logged in");
      }
    } catch (error) {
      console.error("Error fetching user status:", error);
    }
  };

  fetchUserStatus();
}, []); // Empty dependency array ensures this runs only once on component mount

// Function to handle toggle change
const handleToggleChange = async () => {
  try {
    const user = auth.currentUser;
    if (user) {
      const userId = user.uid;
      const userRef = doc(db, "Providers", userId);
      await updateDoc(userRef, {
        status: driverStatus === "Online" ? "Offline" : "Online",
      });
      setDriverStatus((prevStatus) =>
        prevStatus === "Online" ? "Offline" : "Online"
      );
      console.log("Status updated successfully");
    } else {
      console.log("No user logged in");
    }
  } catch (error) {
    console.error("Error updating status:", error);
  }
};


  return (
    <>
      <nav className=" hidden h-20 sm:flex items-center lg:items-stretch justify-end lg:justify-between  bg-green-900 shadow relative z-10">
        <div className="hidden lg:flex w-full pr-6">
          <div className="w-1/2 h-full hidden lg:flex items-center pl-6 pr-24">
            <div className="relative w-full">
              <div className="text-white absolute ml-4 inset-0 m-auto w-4 h-4">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="icon icon-tabler icon-tabler-search"
                  width={16}
                  height={16}
                  viewBox="0 0 24 24"
                  strokeWidth="1.5"
                  stroke="currentColor"
                  fill="none"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                >
                  <path stroke="none" d="M0 0h24v24H0z" />
                  <circle cx={10} cy={10} r={7} />
                  <line x1={21} y1={21} x2={15} y2={15} />
                </svg>
              </div>
              <input
                className="border border-gray-100 focus:outline-none focus:border-green-700 rounded w-full text-sm text-white bg-gray-100 pl-12 py-2"
                type="text"
                placeholder="Search"
              />
            </div>
          </div>
          <div className="w-1/2 hidden lg:flex">
            <div className="w-full flex items-center pl-8 justify-end">
              <div className="h-full w-20 flex items-center justify-center border-r border-l">
                <div className="relative cursor-pointer text-white">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="icon icon-tabler icon-tabler-bell"
                    width={28}
                    height={28}
                    viewBox="0 0 24 24"
                    strokeWidth="1.5"
                    stroke="currentColor"
                    fill="none"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  >
                    <path stroke="none" d="M0 0h24v24H0z" />
                    <path d="M10 5a2 2 0 0 1 4 0a7 7 0 0 1 4 6v3a4 4 0 0 0 2 3h-16a4 4 0 0 0 2 -3v-3a7 7 0 0 1 4 -6" />
                    <path d="M9 17v1a3 3 0 0 0 6 0v-1" />
                  </svg>
                  <div className="w-2 h-2 rounded-full bg-red-400 border border-white absolute inset-0 mt-1 mr-1 m-auto" />
                </div>
              </div>
              <div className="h-full w-20 flex items-center justify-center border-r mr-4 cursor-pointer text-white">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="icon icon-tabler icon-tabler-messages"
                  width={28}
                  height={28}
                  viewBox="0 0 24 24"
                  strokeWidth="1.5"
                  stroke="currentColor"
                  fill="none"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                >
                  <path stroke="none" d="M0 0h24v24H0z" />
                  <path d="M21 14l-3 -3h-7a1 1 0 0 1 -1 -1v-6a1 1 0 0 1 1 -1h9a1 1 0 0 1 1 1v10" />
                  <path d="M14 15v2a1 1 0 0 1 -1 1h-7l-3 3v-10a1 1 0 0 1 1 -1h2" />
                </svg>
              </div>
            
            </div>
          </div>
        </div>
      
      </nav>
     
      <header className="top-0 sm:hidden block bg-white sticky rounded-b-lg p-3">
            <div className="p-0 flex items-center w-full justify-between">
              {/* Back Button */}
              <div onClick={handleGoBack} className="text-black cursor-pointer  hover:text-gray-800">
                <IoChevronBackOutline size={24} />
              </div>
             <div className="w-full"> <Link to="/" className="   ">
                      <img
                        loading="eager"
                        fetchpriority="auto"
                        src={logo}
                        className="w-[80px] h-6"
                        alt="logo"
                      />
                    </Link></div>

                  
              <div className="flex items-center space-x-4 gap-3 flex-row">
              <Link
                    to="/qr"
                    className="text-gray-800 cursor-pointer hover:text-gray-800"
                  >
                    <PiQrCode 
                      size={24}
                      className="text-gray-600 "
                    />
                  </Link>
              <input
               type="checkbox"
               id="switch"
               className="toggle-checkbox hidden"
               onChange={handleToggleChange}
               checked={driverStatus === "Online"}
             
              />
                <label htmlFor="switch" className="toggle-label">
                Toggle
              </label>
          
              </div>
            </div>
         
          
          </header>
    </>
  );
}

export default AdminHeader;
